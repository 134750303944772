<template>
	<div class="page mygroupwork_page">
		<div class="w1200">
			<div class="block30"></div>
			<div class="back_typebox" @click="sysgoback()">
				<img class="icon" src="../assets/static/leftgo.png"/>
				<div class="text fontsize16">返回社团</div>
			</div>
			<div class="block30"></div>
			<div class="mygroupwork_typebox">
				<div class="line"></div>
				<div class="text fontsize32">{{zyssobj.title}}</div>
			</div>
			<div class="block20"></div>
			<div class="topbox">
				<div class="itemlist fontsize16">
					<div class="itemobj">
						<div class="text1">开始时间</div>
						<div class="text2">{{zyssobj.examStartTime}}</div>
					</div>
					<div class="itemobj">
						<div class="text1">类型</div>
						<div class="text2">{{zyssobj.infoTags}}</div>
					</div>
					<!-- <div class="itemobj">
						<div class="text1">结束时间</div>
						<div class="text2">{{zyssobj.examEndTime}}</div>
					</div> -->
					<div class="itemobj">
						<div class="text1">计分结束时间</div>
						<div class="text2">{{zyssobj.scoreEndTime}}</div>
					</div>
					<div class="itemobj">
						<div class="text1">状态</div>
						<div class="text2" v-if="zyssobj.typeTag=='ing'">进行中</div>
						<!-- <div class="text2" v-if="zyssobj.typeTag=='end'">已结束</div> -->
						<div class="text2" v-if="zyssobj.typeTag=='before'">未开始</div>
						<div class="text2" v-if="zyssobj.typeTag=='end'">补做中</div>
					</div>
				</div>
				<div class="textbox">
					<div class="text1 fontsize20">描述</div>
					<div class="text2 fontsize16">{{zyssobj.discription}}</div>
				</div>
			</div>
			<div class="block20"></div>
			<div class="centerbox">
				<div class="typebox fontsize16">
					<div class="itemobj" :class="{'active':typeindex==0}" @click="handletype(0)">
						<div class="text">问题列表</div>
						<div class="line"></div>
					</div>
					<div class="itemobj" :class="{'active':typeindex==1}" @click="handletype(1)">
						<div class="text">完成情况</div>
						<div class="line"></div>
					</div>
					<div class="itemobj" :class="{'active':typeindex==3}" @click="handletype(3)">
						<div class="text">榜单</div>
						<div class="line"></div>
					</div>
				</div>
			</div>
			<div class="block20"></div>
			<div class="bottombox">
				<div class="w100" v-if="typeindex==0">
					<div class="item1 fontsize14">
						<div class="text1">序号</div>
						<div class="text2">题号</div>
						<div class="text3">题目名称</div>
						<div class="text4">完成人数</div>
						<div class="text5">完成情况</div>
					</div>
					<div class="item2 fontsize14" v-for="(item,index) in questionlist" :key="index" @click="gotoworkdetail(index)">
						<div class="text1">{{index+1}}</div>
						<div class="text2">{{item.questNumber}}</div>
						<div class="text3 pointer">{{item.title}}</div>
						<div class="text4">{{item.successNum}}</div>
						<div class="text5" v-if="item.hlQuestExamRecordDetail">
							<span class="c1" v-if="item.hlQuestExamRecordDetail.recordStatus==0">正确</span>
							<span class="c2" v-if="item.hlQuestExamRecordDetail.recordStatus==1">错误</span>
							<span class="c3" v-if="item.hlQuestExamRecordDetail.recordStatus==-1">未开始</span>
						</div>
					</div>
				</div>
				<div class="w100" v-if="typeindex==1">
					<div class="item1 fontsize14">
						<div class="text1">序号</div>
						<div class="text2">答题学生</div>
						<div class="text3">题目名称</div>
						<div class="text4">测评结果</div>
						<div class="text5">提交时间</div>
					</div>
					<div class="item3 fontsize14" v-for="(item,index) in resultlist" :key="index">
						<div class="text1">{{index+1}}</div>
						<div class="text2" v-if="item.hlUser">{{item.hlUser.nikename}}</div>
						<div class="text2" v-if="!item.hlUser">{{item.userUuid}}</div>
						<div class="text3">{{item.title}}</div>
						<div class="text4">
							<span class="c1" v-if="item.recordStatus==0">正确</span>
							<span class="c2" v-if="item.recordStatus==1">错误</span>
						</div>
						<div class="text5">{{item.createDate}}</div>
					</div>
					<div class="nolist" v-if="resultlist.length<=0">暂无相关记录！</div>
				</div>
				<div class="w100" v-if="typeindex==3">
					<div class="item1 fontsize14">
						<div class="text1">序号</div>
						<div class="text2">答题学生</div>
						<div class="text2_1">分数</div>
						<div class="text1">正确数</div>
						<div class="text6" v-for="(item,index) in zyssobj.questNoList">
						   第{{index+1}}道题
						</div>
					</div>
					<div class="item3 fontsize14" v-for="(item,index) in examUserSumList" :key="index">
						<div class="text1">{{index+1}}</div>
						<div class="text2">{{item.nikename || item.uuid}}</div>
						<div class="text2_1">{{item.hlQuestExamRecord.score2}}</div>
						<div class="text1" style="padding-left: 10px;">{{item.hlQuestExamRecord.trueNum}}</div>
						<div class="text6" v-for="(sitem,sindex) in item.detailVoList" :key="sindex">
							<span class="c1" v-if="sitem.recordStatus==0">正确<span v-if="sitem.recordNum>0">({{sitem.recordNum}}次)</span></span>
							<span class="c2" v-if="sitem.recordStatus==1">错误<span v-if="sitem.recordNum>0">({{sitem.recordNum}}次)</span></span>
							<span class="c3" v-if="sitem.recordStatus==2">未作答<span v-if="sitem.recordNum>0">({{sitem.recordNum}}次)</span></span>
						</div>
					</div>
					<div class="nolist" v-if="examUserSumList.length<=0">暂无相关记录！</div>
				</div>
				
			</div>
		</div>
		<div class="block30"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			uid:null,//社团作业id
			zyssobj:{},//作业详情，
			typeindex:0,//0问题列表 1完成情况 3是榜单
			questionlist:[],//问题列表
			resultlist:[],//完成情况
			examUserSumList:[],//参赛人员得分统计
		};
	},
	mounted: function() {
		if(this.isLogin){
			if(this.$route.query&&this.$route.query.uid){
				this.uid = this.$route.query.uid
				//获取社团详情
				this.getgroupDetail()
			}else{
				this.$alert('参数丢失，请返回重试！', '提示', {
				    confirmButtonText: '好的',
				    callback: action => {
						this.sysgoback()
				    }
				});
			}
		}
	},
	computed: {
		...mapState(['userInfo', 'isLogin','sysRole']),
	},
	methods: {
		//跳转详情
		gotoworkdetail(quesindex){
			// if(this.zyssobj.typeTag=="before"){
			// 	this.$message.info("答题时间未开始！")
			// 	return false;
			// }
			// if(this.zyssobj.typeTag=="end"){
			// 	this.$message.info("答题时间已结束！")
			// 	return false;
			// }
			// ing
			if(quesindex){
				this.$router.push({
					path: "/myworkdetail",
					query:{
						uid:this.uid,
						index:quesindex
					}
				});
			}else{
				this.$router.push({
					path: "/myworkdetail",
					query:{
						uid:this.uid,
					}
				});
			}
		},
		//获取详情和获取问题列表
		getgroupDetail(){
			var _this = this 
			var params = {
				examId: this.uid,
				userUuid:this.userInfo.uuid
			}
			this.$http.post('app/hlexam/hlQuestExam/toDetail', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					if(res.data){
						var examStartTime = _this.$util.getDateTime2(res.data.examStartTime)
						var examEndTime = _this.$util.getDateTime2(res.data.scoreEndTime)
						try{
							res.data["typeTag"] = _this.$util.compareTimes(examStartTime,examEndTime)
						}catch(e){
							res.data["typeTag"] = ""
						}
						//自己的做题情况
						if(res.data.questInfoList){
							res.data.questInfoList.forEach((item, index) => {
								if(!item.hlQuestExamRecordDetail){
									item.hlQuestExamRecordDetail = {
										recordStatus:-1 //未开始
									}
								}
							});
							_this.questionlist = res.data.questInfoList
						}
						_this.zyssobj = res.data
						//获取完成情况
						_this.getByAllExamRecord()
						//参赛人员得分统计
						_this.getexamUserSumList()
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//获取全部人完成情况
		getByAllExamRecord(){
			var _this = this 
			var params = {
				examId: this.uid,
				//userUuid:this.userInfo.uuid,
				currentPage:1,
				pageSize:8888
			}
			this.$http.post('app/hlexam/hlQuestExamRecord/getByAllExamRecord', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					_this.resultlist = res.data.records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//参赛人员得分统计
		getexamUserSumList(){
			var _this = this 
			var params = {
				id: this.uid,
			}
			this.$http.post('app/hlexam/hlQuestExam/examUserSumList2', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					_this.examUserSumList = res.data
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//类型
		handletype(type){
			this.typeindex = type
		},
		
		
	}
};
</script>
<style lang="scss" scoped></style>
